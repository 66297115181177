import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { constructActiveDoc } from "../helpers";
import Anchor from "../components/elements/Anchor";

import "../styles/components/elements/product-list-item.scss";
import SliceZone from "../components/common/SliceZone";

const Product = ({ data }) => {
  if (!data) return null;

  const { prismicSettings: settings, prismicProduct: page } = data;
  const {
    title,
    description,
    subtitle,
    image,
    link,
    link_label: linkLabel,
    brand,
    body,
  } = page.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <Container className="mt-7 mt-md-9 mb-4 mb-md-6">
          <Row>
            <Col md="6" className="text-center">
              <figure className="product-list__media text-center py-5">
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.alt || title || ""}
                />
              </figure>
            </Col>
            <Col md="6" className="product-list__content py-5">
              <header>
                {brand && <small className="text-muted">{brand}</small>}
                <h1 className="h3 mb-0 text-primary">{title}</h1>
                <p className="h3 mt-2 mb-0">{subtitle}</p>
              </header>
              <hr className="hr-small" aria-hidden="true" />
              <RichText
                render={description.richText}
                serializeHyperlink={AnchorPrismic}
              />
              {link.url && linkLabel && (
                <Anchor
                  href={link.url}
                  target={
                    link.target || (link.link_type === "Media" && "_blank")
                  }
                  className="fw-bold"
                >
                  {linkLabel}
                </Anchor>
              )}
              <SliceZone slices={body} settings={settings} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query productQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicProduct(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicProductFragment
    }
  }
`;

export default withPrismicPreview(Product);
